:root {
  color-scheme: dark;
  scrollbar-color: white black;
}

html {
  overflow: hidden;
}

body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2234ae;
  background-image: linear-gradient(315deg, #2234ae 0%, #191714 70%);
  background-attachment: fixed;
  height: 100vh;
}

.clickable {
  cursor: pointer;
}

.modal-header {
  background-color: #2234ae;
  background-image: linear-gradient(315deg, #2234ae 0%, #191714 70%);
  color: white;
  font-weight: bolder;
  font-size: 1.5em;
}

td, th, tr {
    background: none !important;
}

.header-container {
  padding: 20px 5px 10px 5px;
  text-align: center;
  margin-top: 1vh;
}

.header {
  text-align: center;
}

.header-title {
  font-size: 5.5vw;
}

.menu-container {
  padding-top: 10px;
}

.menu-button {
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 130%;
  background: none;
  border: none;
  transition: 300ms;
  vertical-align: center;
}

.menu-button:hover {
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 2px;
}

.menu-button:focus {
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 4px;
}

.info-container {
  padding-top: 20px;
  padding-bottom: 30px;
  text-align: left;
}

.howto-image-container {
  text-align: center;
}

.howto-image {
  max-height: 400px;
}

.start-button {
  color: #3cb44b;
}

.chart-title {
  text-align: center;
}

.chart-container {
  padding: 20px 5px 40px 5px;
  text-align: center;
  box-sizing: border-box;
  border: 5px solid transparent;
  background-clip: padding-box;
}

.participant-pill {
  padding: 6px;
  margin: 2px;
  color: azure;
}

.section-title {
  font-weight: bold;
  font-size: 270%;
  margin-bottom: 20px;
  margin-top: 30px;
}

.table {
  font-size: 110%;
}

.cloud-container {
  overflow-y: hidden;
  overflow-x: auto;
}

.cloud-wrapper {
  min-height: 800px;
  width: 100%;
  min-width: 1250px;
}

