.header-container {
  padding: 20px 5px 10px 5px;
  text-align: center;
  margin-top: 1vh;
}

.header {
  text-align: center;
}

.header-title {
  font-size: 5.5vw;
}

.menu-container {
  padding-top: 10px;
}

.menu-button {
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 130%;
  background: none;
  border: none;
  transition: 300ms;
  vertical-align: center;
}

.menu-button:hover {
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 2px;
}

.menu-button:focus {
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 4px;
}

.info-container {
  padding-top: 20px;
  padding-bottom: 30px;
  text-align: left;
}

.howto-image-container {
  text-align: center;
}

.howto-image {
  max-height: 400px;
}
