:root {
  color-scheme: dark;
  scrollbar-color: white black;
}

html {
  overflow: hidden;
}

body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2234ae;
  background-image: linear-gradient(315deg, #2234ae 0%, #191714 70%);
  background-attachment: fixed;
  height: 100vh;
}

.clickable {
  cursor: pointer;
}

.modal-header {
  background-color: #2234ae;
  background-image: linear-gradient(315deg, #2234ae 0%, #191714 70%);
  color: white;
  font-weight: bolder;
  font-size: 1.5em;
}
