.chart-title {
  text-align: center;
}

.chart-container {
  padding: 20px 5px 40px 5px;
  text-align: center;
  box-sizing: border-box;
  border: 5px solid transparent;
  background-clip: padding-box;
}

.participant-pill {
  padding: 6px;
  margin: 2px;
  color: azure;
}

.section-title {
  font-weight: bold;
  font-size: 270%;
  margin-bottom: 20px;
  margin-top: 30px;
}

.table {
  font-size: 110%;
}
